<template>
  <div class="ConseillerAccordProfile" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Espace conseiller - Accord {{accord.year}} de {{ accord.beneficiaire.user.first_name }} {{ accord.beneficiaire.user.last_name}}</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->
            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors de la communication avec le serveur,
                veuillez vérifier réessayer</p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="munaExistingErrorModal" id="munaExistingErrorModal" hide-footer title="Numéro de Muna déjà existant" size="lg">
              <div class="text-center">
                <p>Ce numéro de Muna est déjà enregistré pour un autre bénéficiaire</p>
                <p> Veuillez vérifier le numéro de Muna et, le cas échéant, rechercher via votre tableau de bord le bénéficiaire déjà
                  associé à ce numéro de Muna.</p>
                <b-button pill block class="mt-2" variant="outline-dark" hide-footer @click="$bvModal.hide('munaExistingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <!-- Validated Accord -->
            <b-modal ref="validateAccordModal" id="validateAccordModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Valider la demande d'accord du bénéficiaire </strong>
              </template>
              <p> Vous pouvez valider la demande d'accord du bénéficiaire. Un manager devra confirmer la validation pour
                que le bénéficiaire puisse faire des demandes de remboursements liées à cet accord.
              </p>
              <b-button
                block :variant="validateAccordButtonStyle" class="mt-2"
                :disabled="validateAccordInProcess"
                @click='validateAccord'>
                  <b-spinner small label="validate accord spinner" v-if="validateAccordInProcess"></b-spinner>
                  {{ validateAccordButtonText }}
              </b-button>

            </b-modal>

            <b-modal ref="validatedAccordModal" id="validatedAccordModal" size="lg" hide-footer title="Vous avez bien validé la demande d'accord du bénéficiaire">
              <div class="text-center">
                <p>
                  <strong>Vous avez bien validé la demande d'accord du bénéficiaire. La demande est maintenant en attente de validation de la part d'un manager.
                  </strong>
                </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('validatedAccordModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="refuseAccordModal" id="refuseAccordModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Refuser la demande d'accord fournie par le bénéficiaire </strong>
              </template>
              <b-form>
                <b-form-group id="refuse-beneficiaire-message-group" label="Raisons du refus" label-for="refuse-beneficiaire-message-input">
                  <b-form-textarea
                    required v-model="refuseAccordMessage"
                    placeholder="Renseignez les raisons de votre refus."
                    id="refuse-beneficiaire-message-input"
                    maxlength="999"
                    rows="7"
                    @input="$v.refuseAccordMessage.$touch()"
                    :state="$v.refuseAccordMessage.$dirty ? !$v.refuseAccordMessage.$error : null"/>
                </b-form-group>
                <b-button
                  block :variant="refuseAccordButtonStyle" class="mt-2"
                  :disabled="refuseAccordInProcess || $v.refuseAccordMessage.$invalid"
                  @click='refuseAccord'>
                    <b-spinner small label="refuse beneficiaire Spinner" v-if="refuseAccordInProcess"></b-spinner>
                    {{ refuseAccordButtonText }}
                </b-button>
              </b-form>
            </b-modal>

            <b-modal ref="incompleteAccordModal" id="incompleteAccordModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Marquer la demande d'accord comme incomplète </strong>
              </template>
              <b-form>
                <b-form-group id="incomplete-beneficiaire-message-group" label="Raisons du refus" label-for="incomplete-beneficiaire-message-input">
                  <b-form-textarea
                    required v-model="refuseAccordMessage"
                    placeholder="Renseignez les raisons de votre refus."
                    id="incomplete-beneficiaire-message-input"
                    maxlength="999"
                    rows="7"
                    @input="$v.refuseAccordMessage.$touch()"
                    :state="$v.refuseAccordMessage.$dirty ? !$v.refuseAccordMessage.$error : null"/>
                </b-form-group>
                <b-button
                  block :variant="incompleteAccordButtonStyle" class="mt-2"
                  :disabled="incompleteAccordInProcess || $v.refuseAccordMessage.$invalid"
                  @click='incompleteAccord'>
                    <b-spinner small label="refuse beneficiaire Spinner" v-if="incompleteAccordInProcess"></b-spinner>
                    {{ incompleteAccordButtonText }}
                </b-button>
              </b-form>
            </b-modal>

            <b-modal ref="impotsIncompleteAccordModal" id="impotsIncompleteAccordModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Marquer l'avis d'imposition comme insuffisant </strong>
              </template>
              <b-form>
                <b-form-group id="impots-incomplete-beneficiaire-message-group" label="Raisons du refus" label-for="impots-incomplete-beneficiaire-message-input">
                  <b-form-textarea
                    required v-model="refuseAccordMessage"
                    placeholder="Renseignez les raisons de votre refus."
                    id="impots-incomplete-beneficiaire-message-input"
                    maxlength="999"
                    rows="7"
                    @input="$v.refuseAccordMessage.$touch()"
                    :state="$v.refuseAccordMessage.$dirty ? !$v.refuseAccordMessage.$error : null"/>
                </b-form-group>
                <b-form-group id="impots-incomplete-beneficiaire-isAvisSituationMandatory-group" label-for="impots-incomplete-beneficiaire-isAvisSituationMandatory-input">
                  <b-form-checkbox
                    v-model="isAvisSituationMandatory"
                    id="impots-incomplete-beneficiaire-isAvisSituationMandatory-input">
                    Demander l'avis de situation déclarative à l'impôts sur le revenu
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group id="impots-incomplete-beneficiaire-isTeleactualisationMandatory-group" label-for="impots-incomplete-beneficiaire-isTeleactualisationMandatory-input">
                  <b-form-checkbox
                    v-model="isTeleactualisationMandatory"
                    id="impots-incomplete-beneficiaire-isTeleactualisationMandatory-input">
                    Demander les trois dernières téléactualisations de Pôle emploi
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group id="impots-incomplete-beneficiaire-isAttestationPaiementMandatory-group" label-for="impots-incomplete-beneficiaire-isAttestationPaiementMandatory-input">
                  <b-form-checkbox
                    v-model="isAttestationPaiementMandatory"
                    id="impots-incomplete-beneficiaire-isAttestationPaiementMandatory-input">
                    Demander une copie de l'attestation de paiement de Pôle emploi
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  block :variant="impotsIncompleteAccordButtonStyle" class="mt-2"
                  :disabled="impotsIncompleteAccordInProcess || $v.refuseAccordMessage.$invalid"
                  @click='impotsIncompleteAccord'>
                    <b-spinner small label="refuse beneficiaire Spinner" v-if="impotsIncompleteAccordInProcess"></b-spinner>
                    {{ impotsIncompleteAccordButtonText }}
                </b-button>
              </b-form>
            </b-modal>

            <b-modal ref="incompletedAccordModal" id="incompletedAccordModal" size="lg" hide-footer title="Vous avez bien marqué comme incomplète la demande du bénéficiaire">
              <div class="text-center">
                <p><strong>Vous avez bien marqué comme incomplète la demande du bénéficiaire.</strong></p>
                <p> Celui-ci sera invité à compléter / corriger sa demande d'accord de principe </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('incompletedAccordModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="impotsIncompletedAccordModal" id="impotsIncompletedAccordModal" size="lg" hide-footer title="Vous avez demandé des éléments complémentaires à l'avis d'imposition">
              <div class="text-center">
                <p><strong>Vous avez demandé des éléments complémentaires à l'avis d'imposition.</strong></p>
                <p> Le bénéficiaire sera invité à ajouter de nouveaux documents sur son espace personnel</p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('impotsIncompletedAccordModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="refusedAccordModal" id="refusedAccordModal" size="lg" hide-footer title="Vous avez refusé la demande du bénéficiaire">
              <div class="text-center">
                <p><strong>Vous avez refusé la demande du bénéficiaire. </strong></p>
                <p>Un manager devra confirmer le refus d'accord de principe au bénéficiaire.</p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('refusedAccordModal')">Fermer</b-button>
              </div>
            </b-modal>

            <div v-if="accord.conseiller && accord.beneficiaire.muna">
              <div v-if="connectedConseillerUsername === accord.conseiller.user.username">
                <div v-if="accord.state == '2ONGOING' || accord.state == '3INCOMPLET' || accord.state == '3IMPOTINCOMPLET' || accord.state == '4CREFUSED'">
                  <div class="row mt-2">
                    <div class="col-12 col-md-6 text-center">
                      <b-button v-b-toggle.refuse-accord-collapse variant="outline-danger" class="option-button mt-2" block>
                        <span class="when-closed">Refuser ou marquer la demande comme incomplète</span>
                        <span class="when-open">Annuler</span>
                      </b-button>
                    </div>
                    <div class="col-12 col-md-6 text-center">
                      <b-button
                        variant="outline-success" class="option-button mt-2" block
                        @click="$bvModal.show('validateAccordModal')">
                          Valider la demande du bénéficiaire
                      </b-button>
                    </div>
                  </div>

                  <b-collapse id="refuse-accord-collapse" class="mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <b-button
                          block variant="outline-danger" class="mt-2"
                          :disabled="accord.state == '3INCOMPLET'"
                          @click="$bvModal.show('incompleteAccordModal')">
                            Marquer la demande d'accord comme incomplète
                        </b-button>
                      </div>
                      <div class="col-md-4">
                        <b-button
                          block variant="outline-danger" class="mt-2"
                          :disabled="accord.state == '3IMPOTINCOMPLET'"
                          @click="$bvModal.show('impotsIncompleteAccordModal')">
                            Marquer l'avis d'imposition comme insuffisant
                        </b-button>
                      </div>
                      <div class="col-md-4">
                        <b-button
                          block variant="outline-danger" class="mt-2"
                          :disabled="accord.state == '4CREFUSED'"
                          @click="$bvModal.show('refuseAccordModal')">
                              Refuser la demande d'accord de principe du bénéficiaire
                        </b-button>
                      </div>
                    </div>
                  </b-collapse>
                  <hr>
                </div>
              </div>
              <div v-else>
                <strong class="text-danger">
                  Attention ce dossier ne vous est pas attribué, vous ne pouvez donc pas le faire changer de statut.
                  Veuillez vous attribuer le dossier pour pouvoir le modifier
                </strong>
                <div class="row mt-2">
                  <div class="col-12 text-center">
                    <b-button
                      block pill variant="outline-danger" class="mt-2"
                      @click='attributeAccordToHimself'>
                        M'attribuer ce dossier
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="!accord.conseiller">
              <strong class="text-danger">
                Attention ce dossier ne vous est pas attribué, vous ne pouvez donc pas le faire changer de statut.
                Veuillez vous attribuer le dossier pour pouvoir le modifier
              </strong>
              <div class="row mt-2">
                <div class="col-12 text-center">
                  <b-button
                    block pill variant="outline-danger" class="mt-2"
                    @click='attributeAccordToHimself'>
                      M'attribuer ce dossier
                  </b-button>
                </div>
              </div>
            </div>
            <div v-else>
              <strong class="text-danger">
                Veuillez renseigner un numéro de muna pour pouvoir valider ou refuser le dossier
              </strong>
            </div>



            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#BB3634">
            </loading>

            <div class="row mt-2">
              <div class="col-12">
                <strong style="font-size: 1.2em;"> Statut actuel de la demande du bénéficiaire :
                <span v-if="accord.state == '1CREATED'" class="text-secondary"> En cours de remplissage par le bénéficiaire</span>
                <span v-if="accord.state == '2ONGOING'" class="text-primary"> A valider par le conseiller</span>
                <span v-if="accord.state == '3INCOMPLET'" class="text-danger"> Incomplète, à reremplir par le bénéficiaire </span>
                <span v-if="accord.state == '3IMPOTINCOMPLET'" class="text-danger"> Incomplète, avis d'imposition non suffisant</span>
                <span v-if="accord.state == '4CREFUSED'" class="text-danger"> Refusée par le conseiller, en attente de confirmation du manager</span>
                <span v-if="accord.state == '5CVALIDATED'" class="text-success"> Validée par le conseiller, en attente de confirmation du manager</span>
                <span v-if="accord.state == '6MREFUSED'" class="text-danger"> Refusée par le manager</span>
                <span v-if="accord.state == '7MVALIDATED'" class="text-success"> Validée par le manager</span>
                </strong>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                Date de dépôt du dossier (première date de demande de validation):
                  <strong>{{$dayjs(accord.first_ask_validation_date).format('D MMM YYYY')}}</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                Date du dernier changement de statut de la demande :
                  <strong>{{$dayjs(accord.state_changed_date).format('D MMM YYYY')}}</strong>
              </div>
            </div>
            <div 
              v-if="accord.relance_sent"
              class="row"
            >
              <div class="col-12">
                Relance envoyée au bénéficiaire
                <span 
                  v-if="accord.relance_date"
                >
                  le <strong>{{$dayjs(accord.relance_date).format('D MMM YYYY')}}</strong>
                </span>
              </div>
            </div>

            <div v-if="accord.state != '7MVALIDATED' || accord.state != '8PAIEMENT'" class="row">
              <div v-if="accord.refused_message" class="col-12">
                Dans le cas où cette demande a déjà été refusée, voici le message de refus laissé : <br>
                <em>{{ accord.refused_message }}</em>
              </div>
            </div>
            <div v-if="accord.conseiller" class="row">
              <div class="col-12">
                Conseiller attribué :
                <strong>{{ accord.conseiller.user.first_name }} {{ accord.conseiller.user.last_name}}</strong>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <h2>Informations à renseigner par le conseiller </h2>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <b-form-group id="muna-form-group" label="Muna du bénéficiaire" label-for="muna-form-input"
                  label-cols-md="4" label-cols-lg="4" class="mandatory-input">
                  <b-form-input
                    id="muna-form-input"
                    type="text" maxlength="11"
                    v-model.trim.lazy="beneficiaireMuna"
                    @input="$v.beneficiaireMuna.$touch()"
                    :state="$v.beneficiaireMuna.$dirty ? !$v.beneficiaireMuna.$error : null"
                    placeholder="XXXXXXXX.XX">
                  </b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <b-form-group id="memo-form-group" label="Mettre un mémo" label-for="memo-form-input"
                  label-cols-md="4" label-cols-lg="4">
                  <b-form-textarea
                    id="memo-form-input"
                    type="text" maxlength="599"
                    placeholder="Commentaire"
                    v-model.trim.lazy="beneficiaireMemo"
                    @input="$v.beneficiaireMemo.$touch()"
                    :state="$v.beneficiaireMemo.$dirty ? !$v.beneficiaireMemo.$error : null">
                  </b-form-textarea>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center">
                <b-button
                  pill block :variant="saveMunaMemoMessageButtonStyle" size="sm"
                  @click='saveMunaMemoMessage'
                  :disabled="$v.beneficiaireMuna.$invalid || $v.beneficiaireMemo.$invalid">
                    <b-spinner small label="save muna Spinner" v-if="saveMunaMemoMessageInProcess"></b-spinner> {{ saveMunaMemoMessageButtonText }}
                </b-button>
              </div>
            </div>


            <conseillerBeneficiaireProfile
              v-bind:beneficiaire="accord.beneficiaire"
            />

            <conseillerAccordDocumentsProfile
              v-bind:accord="accord"
            />

          </div>
        </div>
    </div>
  </div>
</template>

<script>

import { APIConseillerConnected } from '@/api/APIConseillerConnected'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { isMuna } from '@/validators/validators'

import formTitleComponent from '@/components/formTitleComponent'


const conseillerAccordDocumentsProfile = () => import('@/components/accord/conseillerAccordDocumentsProfile')
const conseillerBeneficiaireProfile = () => import('@/components/beneficiaire/conseillerBeneficiaireProfile')

const apiConseillerConnected = new APIConseillerConnected()

export default {
  name: 'ConseillerAccordProfile',
  components: {
    formTitleComponent,
    conseillerAccordDocumentsProfile,
    conseillerBeneficiaireProfile
  },
  mixins: [
    validationMixin,
  ],
  data: function () {
    return {
      accord: {
        id: '',
        state: '1CREATED',
        state_changed_date: '2020-01-01',
        first_ask_validation_date: '2020-01-01',
        refused_message: '',
        is_avis_situation_mandatory: false,
        is_teleactualisation_mandatory: false,
        is_attestation_paiement_mandatory: false,

        created_at: '',

        beneficiaire: {
          user: {
            first_name: '',
            last_name: '',
            username: '',
          },
          children: [],
          accords: [],
          activite: 'ART',
          metier: '',
          other_first_names: '',
          address: '',
          code_postal: '',
          city: '',
          birthdate: '2020-01-01',
          phone_number: '',
          other_phone_number: '',
          situation: 'CELIB',
          conjoint_first_name: '',
          conjoint_last_name: '',
          is_conjoint_intermittent: false,
          muna: '',
          memo_message: '',
        },

        conseiller: {
          id: '',
          user: {
            first_name: '',
            last_name: '',
            username: ''
          }
        },
      },

      isAccordLoading: true,

      // ------ MUNA ----------
      beneficiaireMuna: '',
      beneficiaireMemo: '',
      saveMunaMemoMessageButtonText: "Enregistrer le muna et votre mémo",
      saveMunaMemoMessageButtonStyle: "outline-info",
      saveMunaMemoMessageInProcess: false,


      // ------ VALIDATE REFUSE ACCORD

      validateAccordButtonText: "Valider la demande d'accord du bénéficiaire",
      validateAccordButtonStyle: "outline-success",
      validateAccordInProcess: false,

      refuseAccordMessage: '',
      refuseAccordButtonText: "Refuser la demande d'accord du bénéficiaire",
      refuseAccordButtonStyle: "outline-danger",
      refuseAccordInProcess: false,

      incompleteAccordButtonText: "Signaler la demande d'accord comme incomplète",
      incompleteAccordButtonStyle: "outline-danger",
      incompleteAccordInProcess: false,

      isAvisSituationMandatory: false,
      isTeleactualisationMandatory: false,
      isAttestationPaiementMandatory: false,
      impotsIncompleteAccordButtonText: "Signaler l'avis d'imposition comme insuffisant",
      impotsIncompleteAccordButtonStyle: "outline-danger",
      impotsIncompleteAccordInProcess: false,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    connectedConseillerUsername () {
      return this.$store.state.user.userUsername
    },
    isLoading () {
      if (this.isAccordLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.conseillerGetAccord()
  },
  validations: {
    refuseAccordMessage: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(999)
    },

    beneficiaireMuna: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      validMuna(value) {
        return isMuna(value)
      }
    },
    beneficiaireMemo: {
      minLength: minLength(2),
      maxLength: maxLength(599),
    },
  },

  methods: {

    // ------------ GETTERS --------------
    conseillerGetAccord () {
      this.isAccordLoading = true
      apiConseillerConnected.getAccord(this.token, this.$route.params.accord_id)
      .then((result) => {
        this.accord = result.data
        this.refuseAccordMessage = result.data.refused_message
        this.beneficiaireMuna = result.data.beneficiaire.muna
        this.beneficiaireMemo = result.data.beneficiaire.memo_message
        this.isAvisSituationMandatory = result.data.is_avis_situation_mandatory
        this.isTeleactualisationMandatory = result.data.is_teleactualisation_mandatory
        this.isAttestationPaiementMandatory = result.data.is_attestation_paiement_mandatory
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.isAccordLoading = false
      })
    },


    // ------------ MODIFICATION ---------------

    saveMunaMemoMessage () {
      this.saveMunaMemoMessageButtonText = "Enregistrement en cours"
      this.saveMunaMemoMessageButtonStyle = "secondary"
      this.saveMunaMemoMessageInProcess = true
      apiConseillerConnected.saveMunaMemoMessage(
        this.token, this.accord.beneficiaire.id, this.beneficiaireMuna, this.beneficiaireMemo)
      .then(() => {
        this.conseillerGetAccord()
      })
      .catch((error) => {
        if (error.response && error.response.data.muna && error.response.data.muna[0] === 'MUNA_ALREADY_EXISTING') {
          this.$refs.munaExistingErrorModal.show()
        }
        else {
          this.$refs.globalAPILoadingErrorModal.show()
        }
      })
      .finally(() => {
        this.saveMunaMemoMessageButtonText = "Enregistrer le muna et votre mémo"
        this.saveMunaMemoMessageButtonStyle = "outline-info"
        this.saveMunaMemoMessageInProcess = false
      })
    },

    attributeAccordToHimself () {
      this.isAccordLoading = true
      apiConseillerConnected.attributeAccordsToHimself(this.token, [this.$route.params.accord_id])
      .then(() => {
        this.conseillerGetAccord()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.isAccordLoading = false
      })
    },


    // -------------- VALIDATION REFUS -------------

    validateAccord () {
      this.validateAccordButtonText = "Validation en cours"
      this.validateAccordButtonStyle = "secondary"
      this.validateAccordInProcess = true
      apiConseillerConnected.validateAccord(this.token, this.accord.id)
      .then(() => {
        this.conseillerGetAccord()
        this.$refs.validateAccordModal.hide()
        this.$refs.validatedAccordModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.validateAccordButtonText = "Valider la demande d'accord du bénéficiaire"
        this.validateAccordButtonStyle = "outline-success"
        this.validateAccordInProcess = false
      })
    },

    refuseAccord () {
      this.refuseAccordButtonText = "Envoi en cours"
      this.refuseAccordButtonStyle = "secondary"
      this.refuseAccordInProcess = true

      apiConseillerConnected.refuseAccord(this.token, this.accord.id, this.refuseAccordMessage)
      .then(() => {
        this.conseillerGetAccord()
        this.$refs.refusedAccordModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$root.$emit('bv::toggle::collapse', 'refuse-accord-collapse')
        this.$refs.refuseAccordModal.hide()
        this.refuseAccordButtonText = "Refuser la demande d'accord du bénéficiaire"
        this.refuseAccordButtonStyle = "outline-danger"
        this.refuseAccordInProcess = false
      })
    },

    incompleteAccord () {
      this.incompleteAccordButtonText = "Refus en cours"
      this.incompleteAccordButtonStyle = "secondary"
      this.incompleteAccordInProcess = true

      apiConseillerConnected.incompleteAccord(this.token, this.accord.id, this.refuseAccordMessage)
      .then(() => {
        this.conseillerGetAccord()
        this.$refs.incompletedAccordModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$root.$emit('bv::toggle::collapse', 'refuse-accord-collapse')
        this.$refs.incompleteAccordModal.hide()
        this.incompleteAccordButtonText = "Signaler la demande d'accord comme incomplète"
        this.incompleteAccordButtonStyle = "outline-danger"
        this.incompleteAccordInProcess = false
      })
    },

    impotsIncompleteAccord () {
      this.impotsIncompleteAccordButtonText = "Refus en cours"
      this.impotsIncompleteAccordButtonStyle = "secondary"
      this.impotsIncompleteAccordInProcess = true

      apiConseillerConnected.impotsIncompleteAccord(this.token, this.accord.id,
        this.refuseAccordMessage, this.isAvisSituationMandatory, this.isTeleactualisationMandatory, this.isAttestationPaiementMandatory)
      .then(() => {
        this.conseillerGetAccord()
        this.$refs.impotsIncompletedAccordModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$root.$emit('bv::toggle::collapse', 'refuse-accord-collapse')
        this.$refs.impotsIncompleteAccordModal.hide()
        this.impotsIncompleteAccordButtonText = "Signaler l'avis d'imposition comme insuffisant"
        this.impotsIncompleteAccordButtonStyle = "outline-danger"
        this.impotsIncompleteAccordInProcess = false
      })
    },
  }
}
</script>
<style>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
